<template>
  <div class="wrapper wrapper--default p-2 m-1">
    <h2 class="text-center">Verkoop orders - {{franchise}}</h2>
    <hr>
    <Loading v-if="loading" />
    <SalesOrderTable v-else :table_data="franchise_data" :table_data_total="franchise_data_sum" table_style="210" franchise="True" />
  </div>
</template>

<script>
import request from "@/functions/request.js";
import SalesOrderTable from "@/components/SalesOrderTable.vue";
import Loading from "@/components/Loading.vue";

export default {
  props: ["franchise"],
  components: { SalesOrderTable, Loading },
  data: () => ({
    franchise_data: null,
    franchise_data_sum: null,
    loading: true,
  }),
  created() {
    this.getData(this.franchise);
  },
  watch: {
    franchise(newfranchise) {
        this.loading = true;
        this.franchise_data = null;
        this.franchise_data_sum = null;
        this.getData(newfranchise);
    }
  },
  methods: {
    getData(franchise) {
      request(`sales-orders-per-franchise/${franchise}`, "GET").then(
        ({ franchise_data, franchise_data_sum }) => {
          this.franchise_data = franchise_data;
          this.franchise_data_sum = franchise_data_sum;
          this.loading = false;
        }
      );
    },
  },
};
</script>
